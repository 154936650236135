<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="updateSubCategory">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Category Title</label
                      >
                      <select class="form-control" v-model="form.category_id">
                        <option
                          v-for="(item, index) in categories"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Sub Category Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model="form.title"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Sub Category Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model="form.slug"
                        placeholder="Enter category slug"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                  >
                    Update Sub Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
export default {
  name: "update Sub Category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Update Sub Category",
      form: {
        id: this.$route.params.id,
        category_id: null,
        title: null,
        slug: null,
      },
      category: {},
      categories: null,
    };
  },
  mounted() {
    this.singleSubCategory();
    this.allCategory();
  },
  methods: {
    singleSubCategory() {
      this.$axios
        .get("sub-categories/" + this.form.id)
        .then((response) => {
          this.category = response.data;
          this.form.category_id = this.category.category_id;
          this.form.title = this.category.title;
          this.form.slug = this.category.slug;
          //   console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    allCategory() {
      this.$axios
        .get("categories")
        .then((response) => {
          this.categories = response.data.data;
          console.log(this.categories);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateSubCategory() {
      this.$axios
        .put("sub-categories/" + this.form.id, this.form)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.$router.push({
            name: "sub-category",
          });
          console.log(response);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.log(error);
        });
    },
  },
};
</script>